import React from "react";

import { Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Docs = () => {
  return (
    <Page title="Прочие документы Фонда" path="/fund/docs" parent="fund">
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1l40BaDdObDrNqg4RdR3amgSepyvL0XdD/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Базовый стандарт защиты прав и интересов физических и юридических лиц
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1pUdYHF9xshHlkgybbhgF6Cts1ecKn05G/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Базовый стандарт по управлению рисками
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1p7BMmJb_rFs4gxVi1U3PkHauqPAsXUZt/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Базовый стандарт совершения операций на финансовом рынке
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1UPeVgDja2lce8sguY_gW1GTGkkshmiIz/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Порядок разъяснения условий финановых услуг
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1cDVPXp6wLcT81xX6gvUXfFQxS8DwAEzu/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Требования к содержанию обращения получателя финансовой услуги
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1vtXY0EMQW5gQJOp2QRANdu4lkeGi9EF0/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Уведомление о рисках
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1yrJ5B0jwTL95FTz_LxJxTqasFhz7kcCx/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Финансовые услуги Фонда
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1v43KyZ9dK5CSbph_K9K0X4LJgrYpOTl_/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          ФЗ №151-ФЗ от 02.07.2010 "О микрофинансовой деятельности и
          микрофинансовых организациях"
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1ClecBTvl35jGTrDzbu378IWGL1UtNLRj/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          ФЗ № 230-ФЗ от 03.07.2016 "О защите прав и законных интересов
          физических лиц при осуществлении деятельности по возврату просроченной
          задолженности"
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1SQLBipy1ayWcfxO2uxTj8-YfMz6B7Kcx/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Разъяснения Федерального закона от 08.03.2022 № 46-ФЗ о предоставлении
          кредитных каникул для бизнеса
        </StyledLink>
      </Paragraph1>
    </Page>
  );
};

export default Docs;
